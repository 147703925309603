
$(document).foundation();
$(document).ready(function(){
  if (!Cookies.get('cookies') && $('#cookies').length > 0){
    $("#cookies").addClass('is-active');
  }
  $(document).on("click", "#cookies .button", function(){
    $("#cookies").removeClass("is-active");
    Cookies.set('cookies', true, { expires: 60 });
  });
});
